
<style>
.ivu-btn {
    background-color: #19be6b;
    color: white;
    cursor: pointer;
    border: none;
    padding: 5px 6px;
    border-radius: 3px;
    font-size: 12px;
    margin-left: 14px;
}
.ivu-btn-type2{
    background-color: #1488f5;
    color: white;
    cursor: pointer;
    border: none;
    padding: 5px 6px;
    border-radius: 3px;
    font-size: 12px;
    margin-left: 14px;
    
}
.ivu-btn:hover {
    background-color: #47cb89;
}

.ivu-btn-type2:hover {
    background-color: #418bd1;
}
</style>
<template>
    <div style="width: 100%;height: 100%;">
        <div style="box-sizing:border-box;padding:30px;">
            <a-table bordered="true" size="middle" :dataSource="dataSource" :columns="columns">
                <template #operation="{}">
                    <div>
                        <button @click="createNewTest" type="button" class="ivu-btn">
                            <span>开始新学员体测</span>
                        </button>

                      
                    </div>
                </template>
            </a-table>
        </div>
       
    </div>
</template>
  
<script>
export default {
    name: 'fitnessA',
    props: {
    },
    methods: {
        createNewTest(){
            this.$router.push('./addTest')
        },
        gopldfExcl(){
            // window.open('/pldfExcl', '_blank')
        }
    },
    setup() {
        return {
            // showMask:true,
            dataSource: [
                {
                    key: '1',
                    age: '3-6岁儿童体测',
                    detail: '体测项目：10米折返跑，立定跳远，网球掷远，双脚连续跳，坐位体前屈，走平衡木'
                }
            ],

            columns: [
                {
                    title: '体测年龄段',
                    dataIndex: 'age',
                    key: 'age',
                    width: 200,
                },
                {
                    title: '体测项简介',
                    dataIndex: 'detail',
                    key: 'detail',
                },
                {
                    title: '操作',
                    dataIndex: 'operation',
                    key: 'operation',
                    width: 300,
                    slots: { customRender: 'operation' },
                },
            ],
        };
    },
}
</script>
  