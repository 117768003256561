<style scoped>
a-form-item {
  line-height: 0.5;
}
</style>

<template>
  <a-modal :visible="visible" :title="modalTitle" @cancel="hide" :width="585" :key="componentKey">
    <!-- 自定义模态footer -->
    <template #footer>
      <div style="display: flex;align-items: center;justify-content: flex-end;">

        <div v-if="modalFooter">
          <a-button key="back" @click="hide">{{ modalCancelText }}</a-button>
          <a-button v-if="showConfirmBtn" key="submit" type="primary" :loading="loading" @click="handleOk">{{
            modalConfirmText }}</a-button>
        </div>
        <div v-else style="display: flex;align-items: center;justify-content: space-between;width: 100%;">
          <slot name="footer"></slot>
        </div>
      </div>

    </template>

    <!-- 模态内容 -->
    <div v-if="modalContent"
      style="padding: 20px 0;border-top: 1px solid #eeeeee;box-sizing: border-box;font-size: 15px;">
      {{ modalContent }}
    </div>
    <!-- 模态表单 -->
    <div style="padding-top: 15px;border-top: 1px solid #eeeeee;box-sizing: border-box;">
      <slot name="body"></slot>
      <a-form :label-col="{ span: 4, offset: 1 }" :wrapper-col="{ span: 12 }" style="width: 100%;">
        <div v-for="(item, index) in modalForm" :key="index" style="margin-top: 5px;">
          <div v-if="item.component == 'input' && !item.hide">
            <a-form-item :label="item.label" v-bind="validateInfos[item.name]">
              <a-input v-model:value="modelRef[item.name]" :maxlength="item.max" :placeholder="item.placeholder"
                :disabled="item.disabled" @blur="validate('name', { trigger: 'blur' }).catch(() => { })" />
            </a-form-item>
          </div>
          <div v-else-if="item.component == 'label' && !item.hide">
            <a-form-item :label="item.label" v-bind="validateInfos[item.name]">
              <div style="font-size: 16px;padding-left: 10px;">{{ item.value }}</div>
            </a-form-item>
          </div>
          <div v-else-if="item.component == 'select' && !item.hide">
            <a-form-item :label="item.label" v-bind="validateInfos[item.name]">
              <a-select :options="item.options" v-model:value="modelRef[item.name]" placeholder="请选择">
              </a-select>
            </a-form-item>
          </div>
        </div>
      </a-form>
    </div>
  </a-modal>
</template>
<script>
import { defineComponent, reactive, toRaw, getCurrentInstance, ref, reset } from 'vue';
import { Form } from 'ant-design-vue';
const useForm = Form.useForm;
export default {
  name: 'Modal',
  props: {},
  mixins: [],
  components: [],
  setup() {
    const instance = getCurrentInstance();
    const that = instance.appContext.config.globalProperties;
    var modelRef = reactive({});
    var rulesRef = reactive({});
    const visible = ref(false);
    const loading = ref(false);
    const modalFooter = ref(true);
    const showConfirmBtn = ref(true);
    const modalForm = ref([]);
    const modalTitle = ref('');
    const modalContent = ref('');
    const modalCancelText = ref('取消');
    const modalConfirmText = ref('保存');
    const componentKey = ref(0);


    let {
      resetFields,
      validate,
      validateInfos,
    } = useForm(modelRef, rulesRef);


    const methods = {
      show(params = {}, callback = () => { }) {
        let { showFooter = true, title = '标题', content = '', form = [], cancelText = '取消', confirmText = '保存', showConfirmButton = true } = params;
        showConfirmBtn.value = showConfirmButton
        instance.callback = callback;
        that.content = content;
        // 循环删除每个属性
        Object.keys(modelRef).forEach(key => {
          delete modelRef[key];
        });
        Object.keys(rulesRef).forEach(key => {
          delete rulesRef[key];
        });



        form.forEach(item => {
          item.component = item.component || 'input';
          item.placeholder = item.placeholder || '';
          item.disabled = item.disabled || false;
          item.hide = item.hide || false;
          item.value = item.value;
          item.options = ref(item.options)
          modelRef[item.name] = item.value
          if (item.rules && item.rules.length > 0 && item.rules[0].required && !item.rules[0].message) {
            item.rules[0].message = '不能为空'
          }
          rulesRef[item.name] = item.rules || []
        })
        visible.value = true;
        modalForm.value = form;
        modalTitle.value = title;
        modalContent.value = content;
        modalCancelText.value = cancelText;
        modalConfirmText.value = confirmText;
        modalFooter.value = showFooter
        console.log(modelRef, rulesRef)
        // console.log('模态数据', form)
      },

      handleOk() {
        validate().then(res => {
          console.log('返回的数据', modelRef)
          loading.value = true;
          instance.callback(modelRef).then(res => {
            methods.hide()
          }).catch(err => {
          }).finally(err => {
            loading.value = false;
          })
        }).catch(err => {
          console.log(err)
          that.$message.error('请完成填写后再提交！')
        })
      },

      hide() {
        visible.value = false
        // rulesRef.value = {}
        // modalForm.value = {}
        // modelRef.value = {};
        // rulesRef.value = {};
        // modalForm.value = [];
      },
    }
    return {
      showConfirmBtn,
      validate,
      validateInfos,
      resetFields,
      modelRef,
      modalForm,
      ...methods,
      modalTitle,
      modalContent,
      modalCancelText,
      modalConfirmText,
      visible,
      loading,
      modalFooter,
      componentKey
    };
  },

}
</script>
<style scoped></style>
