
<style scoped>
.btn {
    --btn-backgroundColor: #19be6b;
    --btn-hover-backgroundColor: #47cb89;
}

.btn {
    background-color: var(--btn-backgroundColor);
    color: white;
    cursor: pointer;
    border: none;
    padding: 4px 8px;
    border-radius: 3px;
    font-size: 12px;
    /* margin-left: 14px; */
    margin: 2px 0 2px 14px;
    box-sizing: border-box;

}

.btn:hover {
    background-color: var(--btn-hover-backgroundColor);
}
</style>
<template>
    <div style="width: 100%;min-height: 100%;">
        <div style="box-sizing:border-box;padding:30px;">
            <div
                style="flex:1;padding:20px 30px;background-color:#f2f2f2;border-radius:4px;margin-bottom:10px;display:flex;box-sizing:border-box;">
                <div>
                    <div style="font-size:12px;color:#555555;padding-bottom:5px;">学员姓名</div>
                    <a-input v-model:value="name" placeholder="请输入学员姓名" style="width:200px;; border-radius: 4px"></a-input>
                </div>

                <div style="margin-left:20px; ">
                    <div style="font-size:12px;color:#555555;padding-bottom:5px;">手机号</div>
                    <a-input v-model:value="phone" placeholder="请输入学员手机号"
                        style="width:200px;; border-radius: 4px"></a-input>
                </div>
                <div style="margin-left:20px; ">
                    <div style="font-size:12px;color:#555555;padding-bottom:5px;">空格 </div>
                    <a-button @click="searchStudent" type="primary"
                        style="border-radius:4px;font-size:12px;background-color:#c38e2e" :loading="loading">查询</a-button>
                </div>


            </div>
            <div style="width: 100%;display: flex;justify-content: space-between;">
                <button @click="addAccount" type="button" class="ivu-btn"
                    style="padding:8px 5px;margin-bottom:15px;border-radius:4px;">
                    <span>新增学员</span>
                </button>
                <button type="button" @click="showpldr(record)" class="btn"
                    style="padding:8px 5px;margin-bottom:15px;--btn-backgroundColor: #1488f5;--btn-hover-backgroundColor: #1488f5ea;">
                    <span>批量导入</span>
                </button>




            </div>

            <a-table :loading="loading" bordered="true" size="middle" :dataSource="dataSource" :columns="columns">
                <template #avatar="{ record }">
                    <div style="display:flex;padding-left:10px;">
                        <!-- <a-avatar shape="square" size="large" :style="{ backgroundColor: color, verticalAlign: 'middle' }"
                            src="../assets/boy.png"> :pagination="{size:'large'}"
                        </a-avatar> -->
                        <img v-if="record.sex==0" style="height: 40px;height: 40px;" src="../assets/boy.png" alt="">
                        <img v-else style="height: 40px;height: 40px;" src="../assets/girl.png" alt="">
                    </div>
                </template>
                <template #operation="{ record }">
                    <div>
                        <button type="button" @click="goAddTest(record)" class="btn"
                            style="--btn-backgroundColor: #c38e2e;--btn-hover-backgroundColor: #b38e2e;">
                            <span>开始体测</span>
                        </button>

                        <button type="button" @click="goTestRecord(record)" class="btn"
                            style="--btn-backgroundColor: #1faef0;--btn-hover-backgroundColor: #47c5f7fa;">
                            <span>体测记录</span>
                        </button>



                        <button type="button" @click="editStudent(record)" class="btn"
                            style="--btn-backgroundColor: #ff9900;--btn-hover-backgroundColor: #ef9900;">
                            <span>编辑</span>
                        </button>



                        <button type="button" @click="deleteStudent(record)" class="btn"
                            style="--btn-backgroundColor: #ed4014;--btn-hover-backgroundColor: #bd4014;">
                            <span>删除</span>
                        </button>
                    </div>
                </template>
            </a-table>
        </div>


        <Modal ref="lsads"></Modal>

        <Modal ref="pldr">
            <template v-slot:footer>
                <div style="display: flex;align-items: center;justify-content: space-between;width: 100%;">
                    <div style="float: left;font-size: 13px;color: #555;" v-html="pldrTip"></div>

                    <div style="display: flex;align-items: center;">
                        <a-button style="float: right;" key="back" @click="hidePldr">取消</a-button>
                        <a-button style="float: right;" key="submit" type="primary" :loading="batchAddTest_loading"
                            @click="batchAddTest">开始导入</a-button>
                    </div>
                </div>
            </template>
            <template v-slot:body>
                <div style="padding: 5px 20px;display: flex;flex-direction: column;">
                    <div style="display: flex;align-items: center;">模板：<a href="/模板.xlsx" download="">下载模板</a></div>
                    <div style="display: flex;align-items: center;margin-top: 10px;">从文件导入数据：
                        <a-button style="">选择文件
                            <input ref="fileInput" type="file" @change="importSheet" accept=".xlsx, .xls, .csv"
                                style="opacity: 0;position: absolute;left: 0;top: 0;width: 100%;height: 100%;z-index: 555;">
                        </a-button>
                        <div style="padding-left: 10px;font-size: 13px;">{{ fileName }}</div>
                    </div>

                </div>
            </template>
        </Modal>
    </div>
</template>
  
<script>
import Modal from '../components/Modal/Modal';
import { defineComponent, reactive, toRaw, getCurrentInstance, onMounted, watch, ref } from 'vue';
import ExcelJS from 'exceljs';

import { Form } from 'ant-design-vue';
const useForm = Form.useForm;
export default {
    name: 'fitnessA',
    components: {
        Modal
    },
    props: {

    },
    data() {
        return {
        }
    },
    setup() {
        const instance = getCurrentInstance();
        const that = instance.appContext.config.globalProperties;
        const loading = ref(false)
        const batchAddTest_loading = ref(false)
        const dataSource = ref([])
        const name = ref('')
        const phone = ref('')
        var pldrTip = ref('')
        var fileName = ref('')
        var sheetContent = ref([])
        onMounted(() => {
            methods.getStudentList()
        })
        watch([phone, name], ([new1, new2], [old1, old2]) => {
            console.log('数据变化')
            if (new1.length == 0 && new2.length == 0) {
                methods.getStudentList()
            }
        });


        const methods = {
            getStudentList() {
                that.$http.post('admin.getStudentList', {
                }).then(res => {
                    dataSource.value = res.list
                }).catch(err => {
                }).finally(err => {
                })
            },
            deleteStudent(record) {
                instance.refs.lsads.show({
                    title: '删除学员',
                    content: '是否删除?',
                    confirmText: '确定'
                }, () => {
                    return that.$http.post('admin.deleteStudent', {
                        ...record
                    }).then(res => {
                        console.log('删除学员返回的数据', res.list)
                        that.$message.success('学员已删除！')
                        this.getStudentList()
                    }).catch(err => {
                    }).finally(err => {
                    })
                })

            },
            editStudent(record) {
                instance.refs.lsads.show({
                    title: '编辑学员信息',
                    form: [
                        {
                            name: 'id',
                            label: 'id',
                            component: 'input',
                            hide: true,
                            value: record.id,
                            rules: [{
                                required: true,
                            }]
                        },

                        {
                            name: 'name',
                            label: '姓名',
                            value: record.name,
                            component: 'input',
                            placeholder: '请输入姓名',
                            rules: [{
                                required: true,
                            }]
                        },
                        {
                            name: 'sex',
                            label: '性别',
                            value: record.sex,
                            component: 'select',
                            placeholder: '请选择性别',
                            options: [{ label: '男', value: 0 }, { label: '女', value: 1 }],
                            rules: [{
                                required: true,
                            }]
                        },
                        {
                            name: 'age',
                            label: '年龄',
                            value: record.age,
                            component: 'select',
                            placeholder: '请选择年龄',
                            options: [
                                { label: '3岁', value: 3 },
                                { label: '3.5岁', value: 3.5 },
                                { label: '4岁', value: 4 },
                                { label: '4.5岁', value: 4.5 },
                                { label: '5岁', value: 5 },
                                { label: '5.5岁', value: 5.5 },
                                { label: '6岁', value: 6 },
                            ],
                            rules: [{
                                required: true,
                            }]
                        },
                        {
                            name: 'phone',
                            label: '手机号',
                            value: record.phone,
                            component: 'input',
                            placeholder: '请输入手机号',
                            max: 11,
                            rules: [{
                                required: true,
                                min: 11,
                                max: 11,
                                pattern: /^[1][3,4,5,7,8][0-9]{9}$/,
                                message: '请输入正确的手机号'
                            }]
                        },
                        {
                            name: '_password',
                            label: '登录密码',
                            value: record._password,
                            component: 'input',
                            placeholder: '请输入登录密码',
                            max: 11,
                            rules: [{
                                required: true,
                                min: 5,
                                message: '最少输入5位数'
                            }]
                        },
                    ]
                }, (data) => {
                    return that.$http.post('admin.editStudent', {
                        ...data
                    }).then(res => {
                        that.$message.success('保存成功！')
                        this.getStudentList()
                    })
                });
            },
            addAccount() {
                instance.refs.lsads.show({
                    title: '新增学员',
                    form: [
                        {
                            name: 'name',
                            label: '姓名',
                            component: 'input',
                            placeholder: '请输入姓名',
                            rules: [{
                                required: true,
                            }]
                        },
                        {
                            name: 'sex',
                            label: '性别',
                            component: 'select',
                            placeholder: '请选择性别',
                            options: [{ label: '男', value: 0 }, { label: '女', value: 1 }],
                            rules: [{
                                required: true,
                            }]
                        },
                        {
                            name: 'age',
                            label: '年龄',
                            component: 'select',
                            placeholder: '请选择年龄',
                            options: [
                                { label: '3岁', value: 3 },
                                { label: '3.5岁', value: 3.5 },
                                { label: '4岁', value: 4 },
                                { label: '4.5岁', value: 4.5 },
                                { label: '5岁', value: 5 },
                                { label: '5.5岁', value: 5.5 },
                                { label: '6岁', value: 6 },
                            ],
                            rules: [{
                                required: true,
                            }]
                        },
                        {
                            name: 'phone',
                            label: '手机号',
                            component: 'input',
                            placeholder: '请输入手机号',
                            max: 11,
                            rules: [{
                                required: true,
                                min: 11,
                                max: 11,
                                pattern: /^[1][3,4,5,7,8][0-9]{9}$/,
                                message: '请输入正确的手机号'
                            }]
                        },
                        {
                            name: '_password',
                            label: '登录密码',
                            component: 'input',
                            placeholder: '请输入登录密码',
                            max: 11,
                            rules: [{
                                required: true,
                                min: 5,
                                message: '最少输入5位数'
                            }]
                        },

                    ]
                }, (data) => {
                    return that.$http.post('admin.addAccount', {
                        ...data
                    }).then(res => {
                        that.$message.success('操作成功！')
                        this.getStudentList()
                    })
                });
            },
            searchStudent() {
                that.$http.post('admin.getStudentList', {
                    phone: phone.value,
                    name: name.value
                }).then(res => {
                    dataSource.value = res.list
                }).catch(err => {
                }).finally(err => {
                })
            },
            goTestRecord(record) {
                that.$router.push({ path: '/testRecord', query: { phone: record.phone, name: record.name } })
            },
            goAddTest(record) {
                that.$router.push({ path: '/addTest', query: { phone: record.phone, name: record.name, age: record.age, sex: record.sex, } })
            },
            showpldr() {
                fileName.value = '';
                sheetContent.value = [];
                pldrTip.value = `已加载0条数据`
                instance.refs.pldr.show({
                    title: '新增学员',
                    showFooter: false
                })
            },
            hidePldr() {
                instance.refs.pldr.hide()
            },
            importSheet(event) {
                const file = event.target.files[0];
                const workbook = new ExcelJS.Workbook();
                // 读取上传的Excel文件
                let temp_sheetData = [];
                workbook.xlsx.load(file).then(workbook => {
                    const worksheet = workbook.getWorksheet(1);
                    worksheet.eachRow((row, rowNumber) => {
                        // 处理每一行数据
                        // console.log('读取的表格数据：', row.getCell(1).value)
                        if (rowNumber > 2) {
                            let num = row.getCell(1).value;
                            let name = row.getCell(2).value;
                            let sex = row.getCell(3).value == '男' ? 0 : 1;
                            let age = Number(Number(row.getCell(4).value).toFixed(0));
                            let height = Number(Number(row.getCell(5).value).toFixed(1));
                            let weight = Number(Number(row.getCell(6).value).toFixed(1));
                            let smzfp = Number(Number(row.getCell(7).value).toFixed(2));
                            let ldty = Number(Number(row.getCell(8).value).toFixed(2));
                            let wqzy = Number(Number(row.getCell(9).value).toFixed(2));
                            let sjlxt = Number(Number(row.getCell(10).value).toFixed(2));
                            let zwtqq = Number(Number(row.getCell(11).value).toFixed(2));
                            let zphm = Number(Number(row.getCell(12).value).toFixed(2));
                            let phone = row.getCell(13).value;
                            let obj = {
                                num, name, sex, age, height, weight, smzfp,
                                ldty, wqzy, sjlxt, zwtqq, zphm, phone
                            }
                            temp_sheetData.push(obj)
                        }
                    })
                    console.log('加载的表格数据', temp_sheetData, '长度', temp_sheetData.length, instance.refs.fileInput.value)
                    fileName.value = instance.refs.fileInput.value;
                    sheetContent.value = temp_sheetData;
                    pldrTip.value = `已加载${sheetContent.value.length}条数据`
                    instance.refs.fileInput.value = null;
                });
            },
            batchAddTest() {
                if (sheetContent.value.length == 0) {
                    return that.$message.error('表格数据为空或格式错误！')
                } else {
                    console.log('请求发送的数据', sheetContent.value)
                    batchAddTest_loading.value = true
                    that.$http.post('admin.batchAddTest', {
                        testList: sheetContent.value
                    }).then(res => {
                        methods.getStudentList()
                        console.log('返回数据', res)
                        pldrTip.value = res.msg
                        if (res.failList.length == 0) {
                            instance.refs.pldr.hide()
                            that.$message.success('导入成功')
                        }

                    }).catch(err => {
                    }).finally(err => {
                        batchAddTest_loading.value = false
                    })
                }
            }
        }
        return {
            ...methods,
            name, phone, dataSource, loading, sheetContent, fileName, batchAddTest_loading, pldrTip,

            columns: [
                {
                    title: '头像',
                    dataIndex: 'avatar',
                    key: 'avatar',
                    width: 70,
                    slots: { customRender: 'avatar' },
                },
                {
                    title: '姓名',
                    dataIndex: 'name',
                    key: 'name',
                    width: 100,
                },
                {
                    title: '年龄',
                    dataIndex: 'age',
                    key: 'age',
                    width: 50,
                },
                {
                    title: '性别',
                    dataIndex: 'sex',
                    key: 'sex',
                    width: 50,
                },
                {
                    title: '电话',
                    dataIndex: 'phone',
                    key: 'phone',
                    width: 110,
                },
                {
                    title: '出生日期',
                    dataIndex: 'birth',
                    key: 'birth',
                    width: 110,
                },
                {
                    title: '操作',
                    dataIndex: 'operation',
                    key: 'operation',
                    width: 300,
                    slots: { customRender: 'operation' },
                },
            ],
        };
    }
}
</script>
  