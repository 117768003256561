import { createApp } from 'vue';
import Antd from 'ant-design-vue';
import App from './App';
import { createRouter, createWebHistory } from 'vue-router'
import routes from './routes.js';
import store from './store';
import http from './http'





const router = createRouter({
    history: createWebHistory(),
    routes:routes
})
const app = createApp(App);
app.use(router)
app.use(store)

app.use(http)

app.config.productionTip = false;

app.use(Antd);
app.mount('#app')
