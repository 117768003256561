
<style>
:where(.css-dev-only-do-not-override-j6gjt1).ant-menu-dark .ant-menu-item-selected {
  background-color: #c38e2e;
  color: #ffffff;
}

:where(.css-dev-only-do-not-override-j6gjt1).ant-menu .ant-menu-item,
:where(.css-dev-only-do-not-override-j6gjt1).ant-menu .ant-menu-submenu,
:where(.css-dev-only-do-not-override-j6gjt1).ant-menu .ant-menu-submenu-title {
  border-radius: 0;
}

:where(.css-dev-only-do-not-override-j6gjt1).ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-submenu-title:hover {
  background-color: #515a6e
}

:where(.css-dev-only-do-not-override-j6gjt1).ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-submenu-title:active {
  background-color: #515a6e
}

.ant-menu-item ant-menu-item-only-child {
  margin: 0;
}

:where(.css-dev-only-do-not-override-j6gjt1).ant-menu-inline .ant-menu-item,
:where(.css-dev-only-do-not-override-j6gjt1).ant-menu-vertical .ant-menu-item,
:where(.css-dev-only-do-not-override-j6gjt1).ant-menu-inline .ant-menu-submenu-title,
:where(.css-dev-only-do-not-override-j6gjt1).ant-menu-vertical .ant-menu-submenu-title {
  margin-inline: 0;
  margin-block: 0;
  width: auto;
  line-height: 45px;
  height: 45px;

}

:where(.css-dev-only-do-not-override-j6gjt1).ant-menu-inline>.ant-menu-item,
:where(.css-dev-only-do-not-override-j6gjt1).ant-menu-vertical>.ant-menu-item,
:where(.css-dev-only-do-not-override-j6gjt1).ant-menu-inline>.ant-menu-submenu>.ant-menu-submenu-title,
:where(.css-dev-only-do-not-override-j6gjt1).ant-menu-vertical>.ant-menu-submenu>.ant-menu-submenu-title {
  line-height: 45px;
  height: 45px;
}

:where(.css-dev-only-do-not-override-j6gjt1).ant-menu-inline .ant-menu-item {
  height: 45px;
  line-height: 45px;
  list-style-position: inside;
  list-style-type: disc;
}

.ant-menu-item-active:active {
  background-color: #c38e2e;
}

.ant-menu-item-active:hover {
  background-color: #c38e2e;
}
</style>
<template>
  <div style="height: 100vh;background-color: #393d42;">
    <div
      style="height: 60px;display: flex;background-color: #282b2e;align-items: center;justify-content: center;color: #ffffff;font-size: 17px;">
      柏然体育
    </div>
    <a-menu @select="menuSelect" style="width: 100%;background-color: #282b2e;user-select: none;color: white;"
      mode="inline" theme="dark" :inline-collapsed="collapsed" :selectedKeys="[selectedMenu]">
      <a-menu-item key="/">
        <template #icon>
          <PieChartOutlined />
        </template>
        <span>首页</span>
      </a-menu-item>
      <a-menu-item key="/fitness">
        <template #icon>
          <DesktopOutlined />
        </template>
        <span>幼儿体测</span>
      </a-menu-item>

      <a-menu-item key="/student">
        <template #icon>
          <DesktopOutlined />
        </template>
        <span>幼儿管理</span>
      </a-menu-item>

      <!-- 

      <a-sub-menu key="student">
        <template #title>
          <div style="padding-left: 24px;">学员管理</div>
        </template>
        <a-menu-item key="/vip">
          <template #icon>
            <DesktopOutlined />
          </template>
          <span>会员</span>
        </a-menu-item>
        <a-menu-item key="/novip">
          <template #icon>
            <DesktopOutlined />
          </template>
          <span>非会员</span>
        </a-menu-item>
      </a-sub-menu> -->

      <a-menu-item key="/account">
        <template #icon>
          <InboxOutlined />
        </template>
        <span>账号管理</span>
      </a-menu-item>
    </a-menu>

  </div>
</template>

<script>
export default {
  name: 'HelloWorld',
  props: {
    msg: String
  },
  created() {
    setTimeout(() => {
      this.selectedMenu = this.$route.path
    }, 0);
  },
  data() {
    return {
      selectedMenu: null
    };
  },
  methods: {
    menuSelect(e) {
      this.selectedMenu = e.key
      this.$router.push(e.key)
      // this.$emit('menuSelect', { key: e.key })
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

a {
  color: #42b983;
}
</style>
