<style scoped>
.ivu-btnd {
  background-color: #19be6b;
  color: white;
  cursor: pointer;
  border: none;
  padding: 2px 13px;
  border-radius: 3px;
  font-size: 12px;
  /* margin-left: 14px; */
  box-sizing: border-box;
}

.ivu-btnd:hover {
  background-color: #47cb89;
}
</style>
<template>
  <div style="width: 100%;height: 100%;position: relative;">
    <img src="../assets/bg.png" style="width: 100%;height: auto;" alt="">
    <div style="background-color: #fff;position: absolute;left: 20px;top: 20px;border-radius: 4px;width: 300px;">
      <div style="font-size: 13px;font-weight: 600;color: #393d42;padding:15px 15px;flex: 1;text-align: left;">欢迎</div>
      <div
        style="width: 100%;border-top: 1px solid #eeeeee;box-sizing: border-box;padding:10px 12px 20px 12px;line-height: 38px;font-size: 18px;color: #393d42;">
        <div>柏然体育</div>
        <div>登录账号：{{ _account }}</div>
        <div>姓名：{{ name }}</div>
        <div>手机号：{{ phone }}</div>
        <a-button @click="modifyPassword" type="primary" style="font-size: 12px;" class="ivu-btnd">修改密码</a-button>
      </div>

    </div>
    <Modal ref="modifyPassword"></Modal>
  </div>
</template>

<script>
import { defineComponent, ref } from 'vue';
import Modal from '../components/Modal/Modal';

export default {
  name: 'indexA',
  components: {
    Modal
  },
  props: {
  },
  setup() {
    const _account = ref('')
    const name = ref('')
    const phone = ref('')

    return {
      _account,
      name,
      phone
    }
  },
  created() {
    this.getAdminInfo()
  },
  methods: {
    modifyPassword() {
      console.log('修改')
      this.$refs.modifyPassword.show({
        title: '修改密码',
        form: [
          { name: '_account', label: '账号', component: 'input', required: true, disabled: true, value: this._account },
          { name: '_password', label: '旧密码', component: 'input', placeholder: '请输入旧密码', required: true },
          { name: '_newPassword', label: '新密码', component: 'input', placeholder: '请输入新密码', required: true },
          { name: '_newPassword_confirm', label: '确认密码', component: 'input', placeholder: '请重新输入新密码', required: true },
        ]
      }, (data) => {
        return this.$http.post('admin.modifyPassword', {
          ...data
        }).then(res => {
          this.$message.success('修改成功')
          this.getAdminInfo()
        }).catch(err => {
        }).finally(err => {
        })
      });
    },
    getAdminInfo() {
      this.$http.post('admin.getAdminInfo', {
      }).then(res => {
        // this.data = { ...this.data, ...res }
        console.log('sss', res)
        this._account = res._account;
        this.name = res.name;
        this.phone = res.phone;
        console.log('xxx',this.$refs)
        
      }).catch(err => {
      }).finally(err => {
        this.loading = false;
      })
    }
  }
}
</script>
