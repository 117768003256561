
<style scoped>
.btn {
  --btn-backgroundColor: #19be6b;
  --btn-hover-backgroundColor: #47cb89;
}

.btn {
  background-color: var(--btn-backgroundColor);
  color: white;
  cursor: pointer;
  border: none;
  padding: 4px 8px;
  border-radius: 3px;
  font-size: 12px;
  /* margin-left: 14px; */
  margin: 2px 0 2px 14px;
  box-sizing: border-box;

}

.btn:hover {
  background-color: var(--btn-hover-backgroundColor);
}
</style>
<template>
  <div style="width: 100%;min-height: 100%;">
    <div style="box-sizing:border-box;padding:30px;">
      <div
        style="flex:1;padding:20px 30px;background-color:#f2f2f2;border-radius:4px;margin-bottom:10px;display:flex;box-sizing:border-box;">
        <div>
          <div style="font-size:12px;color:#555555;padding-bottom:5px;">员工姓名</div>
          <a-input v-model:value="name" placeholder="请输入员工姓名" style="width:200px;; border-radius: 4px"></a-input>
        </div>

        <div style="margin-left:20px; ">
          <div style="font-size:12px;color:#555555;padding-bottom:5px;">手机号</div>
          <a-input v-model:value="phone" placeholder="请输入员工手机号" style="width:200px;; border-radius: 4px"></a-input>
        </div>
        <div style="margin-left:20px; ">
          <div style="font-size:12px;color:#555555;padding-bottom:5px;">空格 </div>
          <a-button @click="searchAdmin" type="primary" style="border-radius:4px;font-size:12px;background-color:#c38e2e"
            :loading="loading">查询</a-button>
        </div>


      </div>
      <button @click="addAccount" type="button" class="ivu-btn"
        style="padding:8px 14px;margin-bottom:15px;border-radius:4px;">
        <span>新增员工</span>
      </button>

      <a-table :loading="loading" bordered="true" size="middle" :dataSource="dataSource" :columns="columns">
        <template #avatar="{ record }">
          <div style="display:flex;padding-left:10px;">
            <a-avatar shape="square" size="large" :style="{ backgroundColor: color, verticalAlign: 'middle' }"
              :src="record.avatar">
            </a-avatar>
          </div>
        </template>
        <template #operation="{ record }">
          <div>

            <button type="button" @click="editAdmin(record)" class="btn"
              style="--btn-backgroundColor: #ff9900;--btn-hover-backgroundColor: #ef9900;">
              <span>编辑</span>
            </button>



            <button type="button" @click="deleteAdmin(record)" class="btn"
              style="--btn-backgroundColor: #ed4014;--btn-hover-backgroundColor: #bd4014;">
              <span>删除</span>
            </button>
          </div>
        </template>
      </a-table>
    </div>


    <Modal ref="lsads"></Modal>
  </div>
</template>
  
<script>
import Modal from '../components/Modal/Modal';
import { defineComponent, reactive, toRaw, getCurrentInstance, onMounted, watch, ref } from 'vue';
import { Form } from 'ant-design-vue';
const useForm = Form.useForm;
export default {
  name: 'fitnessA',
  components: {
    Modal
  },
  props: {

  },
  data() {
    return {
    }
  },
  setup() {
    const instance = getCurrentInstance();
    const that = instance.appContext.config.globalProperties;
    const loading = ref(false)
    const dataSource = ref([])
    let power = ref(0)
    const name = ref('')
    const phone = ref('')
    onMounted(() => {
      methods.getAccountList()
    })
    watch([phone, name], ([new1, new2], [old1, old2]) => {
      console.log('数据变化')
      if (new1.length == 0 && new2.length == 0) {
        methods.getAccountList()
      }
    });


    const methods = {
      getAccountList() {
        that.$http.post('admin.getAccountList', {}).then(res => {
          dataSource.value = res.list
          power.value = res.power
        }).catch(err => {
        }).finally(err => {
        })
      },
      deleteAdmin(record) {
        if (!power.value) {
          return that.$message.error('无操作权限')
        }
        instance.refs.lsads.show({
          title: '删除员工',
          content: '是否删除?',
          confirmText: '确定'
        }, () => {
          return that.$http.post('admin.deleteAdmin', {
            ...record
          }).then(res => {
            console.log('删除员工返回的数据', res.list)
            that.$message.success('员工已删除！')
            this.getAccountList()
          }).catch(err => {
          }).finally(err => {
          })
        })

      },
      editAdmin(record) {
        if (!power.value) {
          return that.$message.error('无操作权限')
        }
        console.log('admin', record)
        instance.refs.lsads.show({
          title: '编辑员工信息',
          form: [
            {
              name: 'id',
              label: 'id',
              component: 'input',
              placeholder: '请输入姓名',
              hide: true,
              value: record.id,
              rules: [{
                required: true,
              }]
            },
            {
              name: 'name',
              label: '姓名',
              component: 'input',
              placeholder: '请输入姓名',
              value: record.name,
              rules: [{
                required: true,
              }]
            },
            {
              name: 'phone',
              label: '手机号',
              value: record.phone,
              component: 'input',
              placeholder: '请输入手机号',
              max: 11,
              rules: [{
                required: true,
                min: 11,
                max: 11,
                pattern: /^[1][3,4,5,7,8][0-9]{9}$/,
                message: '请输入正确的手机号'
              }]
            },
            {
              name: '_account',
              label: '登录账号',
              component: 'input',
              placeholder: '请输入登录账号',
              value: record._account,
              max: 11,
              rules: [{
                required: true,
                min: 5,
                message: '最少输入5位数'
              }]
            },
            {
              name: '_password',
              label: '登录密码',
              component: 'input',
              placeholder: '请输入登录密码',
              value: record._password,
              max: 11,
              rules: [{
                required: true,
                min: 5,
                message: '最少输入5位数'
              }]
            },
            {
              name: 'address',
              label: '住址',
              component: 'input',
              value: record.address,
              placeholder: '请输入住址',
              rules: [{
                required: false,
              }]
            },
            {
              name: 'remarks',
              label: '备注',
              component: 'input',
              value: record.remarks,
              placeholder: '请输入备注',
              rules: [{
                required: false,
              }]
            },
          ]
        }, (data) => {
          return that.$http.post('admin.editAdmin', {
            ...data
          }).then(res => {
            that.$message.success('保存成功！')
            this.getAccountList()
          })
        });
      },
      addAccount() {
        console.log('操作权限查看', power)
        if (!power.value) {
          return that.$message.error('无操作权限')
        }
        instance.refs.lsads.show({
          title: '新增员工',
          form: [
            {
              name: 'name',
              label: '姓名',
              component: 'input',
              placeholder: '请输入姓名',
              rules: [{
                required: true,
              }]
            },
            {
              name: 'phone',
              label: '手机号',
              component: 'input',
              placeholder: '请输入手机号',
              max: 11,
              rules: [{
                required: true,
                min: 11,
                max: 11,
                pattern: /^[1][3,4,5,7,8][0-9]{9}$/,
                message: '请输入正确的手机号'
              }]
            },
            {
              name: '_account',
              label: '登录账号',
              component: 'input',
              placeholder: '请输入登录账号',
              max: 11,
              rules: [{
                required: true,
                min: 5,
                message: '最少输入5位数'
              }]
            },
            {
              name: '_password',
              label: '登录密码',
              component: 'input',
              placeholder: '请输入登录密码',
              max: 11,
              rules: [{
                required: true,
                min: 5,
                message: '最少输入5位数'
              }]
            },
            {
              name: 'address',
              label: '住址',
              component: 'input',
              placeholder: '请输入住址',
              rules: [{
                required: false,
              }]
            },
            {
              name: 'remarks',
              label: '备注',
              component: 'input',
              placeholder: '请输入备注',
              rules: [{
                required: false,
              }]
            },
          ]
        }, (data) => {
          return that.$http.post('admin.addAdminAccount', {
            ...data
          }).then(res => {
            that.$message.success('操作成功！')
            this.getAccountList()
          })
        });
      },
      searchAdmin() {
        that.$http.post('admin.getAccountList', {
          phone: phone.value,
          name: name.value
        }).then(res => {
          dataSource.value = res.list
        }).catch(err => {
        }).finally(err => {
        })
      },

    }
    return {
      ...methods,
      name, phone, dataSource, loading, power,

      columns: [
        {
          title: '头像',
          dataIndex: 'avatar',
          key: 'avatar',
          width: 100,
          slots: { customRender: 'avatar' },
        },
        {
          title: '姓名',
          dataIndex: 'name',
          key: 'name',
          width: 100,
        },
        {
          title: '电话',
          dataIndex: 'phone',
          key: 'phone',
        },
        {
          title: '住址',
          dataIndex: 'address',
          key: 'address',
        },
        {
          title: '备注',
          dataIndex: 'remarks',
          key: 'remarks',
        },
        {
          title: '操作',
          dataIndex: 'operation',
          key: 'operation',
          width: 300,
          slots: { customRender: 'operation' },
        },
      ],
    };
  }
}
</script>
  