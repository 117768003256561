<style >
.ivu-btn-blue {
    background-color: #1faef0;
    color: white;
    cursor: pointer;
    border: none;
    border-radius: 5px;
    font-size: 15px;
    height: 35px;
    /* margin-left: 14px; */
    box-sizing: border-box;
}

.ivu-btn-blue:hover {
    background-color: #47c5f7fa !important;

}

:where(.css-dev-only-do-not-override-j6gjt1).ant-card .ant-card-head {
    font-size: 14px;
}

:where(.css-dev-only-do-not-override-j6gjt1).ant-form-item .ant-form-item-label>label {
    font-size: 16px;
    font-weight: normal;
}
</style>
<template>
    <div style="width: 100%;height: 100%;position: relative;">
        <div style="padding:25px;font-weight: 600;font-size: 18px;">
            <div style="color: #555555;">幼儿体测（新学员）</div>
            <a-form :label-col="labelCol" :wrapper-col="wrapperCol" style="width: 100%;">
                <a-card title="3-6岁儿童体测" style="margin-top: 23px;">
                    <a-form-item name="测试时间" label="测试时间" v-bind="validateInfos.testTime">
                        <a-date-picker v-model:value="modelRef.testTime" value-format="YYYY-MM-DD" />
                    </a-form-item>
                    <a-form-item label="姓名" v-bind="validateInfos.name">
                        <a-input v-model:value="modelRef.name"
                            @blur="validate('name', { trigger: 'blur' }).catch(() => { })" />
                    </a-form-item>
                    <a-form-item label="性别" v-bind="validateInfos.sex">
                        <a-select v-model:value="modelRef.sex" placeholder="请选择">
                            <a-select-option value="0">男</a-select-option>
                            <a-select-option value="1">女</a-select-option>
                        </a-select>
                    </a-form-item>
                    <a-form-item label="年龄" v-bind="validateInfos.age">
                        <a-select v-model:value="modelRef.age" placeholder="请选择">
                            <a-select-option value="3">3岁</a-select-option>
                            <a-select-option value="3.5">3.5岁</a-select-option>
                            <a-select-option value="4">4岁</a-select-option>
                            <a-select-option value="4.5">4.5岁</a-select-option>
                            <a-select-option value="5">5岁</a-select-option>
                            <a-select-option value="5.5">5.5岁</a-select-option>
                            <a-select-option value="6">6岁</a-select-option>
                        </a-select>
                    </a-form-item>
                    <a-form-item label="电话" v-bind="validateInfos.phone">
                        <a-input v-model:value="modelRef.phone" :maxlength="11"
                            @blur="validate('phone', { trigger: 'blur' }).catch(() => { })" />
                    </a-form-item>
                    <a-form-item name="出生日期" label="出生日期" v-bind="validateInfos.birth">
                        <a-date-picker v-model:value="modelRef.birth" value-format="YYYY-MM-DD" />
                    </a-form-item>
                    <a-form-item label="身高" v-bind="validateInfos.height">
                        <div style="display: flex;align-items: center;position: relative;">

                            <a-input-number v-model:value="modelRef.height" :precision="1" style="width: 100%" :step="0.1"
                                string-mode />
                            <span style="position: absolute;right: -35px;width: 30px;">cm</span>
                        </div>
                    </a-form-item>

                    <a-form-item label="体重" v-bind="validateInfos.weight">
                        <div style="display: flex;align-items: center;position: relative;">
                            <a-input-number v-model:value="modelRef.weight" :precision="1" style="width: 100%" :step="0.1"
                                string-mode />
                            <span style="position: absolute;right: -35px;width: 30px;">kg</span>
                        </div>
                    </a-form-item>
                    <div
                        style="border-bottom: 3px solid #f0f0f0;font-size: 22px;font-weight: normal;color: #555555;margin-bottom: 20px;">
                        素质项</div>

                    <a-form-item label="10米折返跑" v-bind="validateInfos.smzfp">
                        <div style="display: flex;align-items: center;position: relative;">
                            <a-input-number v-model:value="modelRef.smzfp" :precision="2" style="width: 100%" :step="0.01"
                                string-mode />
                            <span style="position: absolute;right: -35px;width: 30px;">s</span>
                        </div>

                    </a-form-item>

                    <a-form-item label="立定跳远" v-bind="validateInfos.ldty">
                        <div style="display: flex;align-items: center;position: relative;">
                            <a-input-number v-model:value="modelRef.ldty" :precision="2" style="width: 100%" :step="0.01"
                                string-mode />
                            <span style="position: absolute;right: -35px;width: 30px;">m</span>
                        </div>

                    </a-form-item>
                    <a-form-item label="网球掷远" v-bind="validateInfos.wqzy">
                        <div style="display: flex;align-items: center;position: relative;">
                            <a-input-number v-model:value="modelRef.wqzy" :precision="2" style="width: 100%" :step="0.01"
                                string-mode />
                            <span style="position: absolute;right: -35px;width: 30px;">m</span>

                        </div>

                    </a-form-item>
                    <a-form-item label="双脚连续跳" v-bind="validateInfos.sjlxt">
                        <div style="display: flex;align-items: center;position: relative;">
                            <a-input-number v-model:value="modelRef.sjlxt" :precision="2" style="width: 100%" :step="0.01"
                                string-mode />
                            <span style="position: absolute;right: -35px;width: 30px;">s</span>
                        </div>
                    </a-form-item>
                    <a-form-item label="坐位体前屈" v-bind="validateInfos.zwtqq">
                        <div style="display: flex;align-items: center;position: relative;">
                            <a-input-number v-model:value="modelRef.zwtqq" :precision="2" style="width: 100%" :step="0.01"
                                string-mode />
                            <span style="position: absolute;right: -35px;width: 30px;">cm</span>
                        </div>

                    </a-form-item>
                    <a-form-item label="走平衡木" v-bind="validateInfos.zphm">
                        <div style="display: flex;align-items: center;position: relative;">
                            <a-input-number v-model:value="modelRef.zphm" :precision="2" style="width: 100%" :step="0.01"
                                string-mode />
                            <span style="position: absolute;right: -35px;width: 30px;">s</span>
                        </div>
                    </a-form-item>
                    <div
                        style="border-bottom: 3px solid #f0f0f0;font-size: 22px;font-weight: normal;color: #555555;margin-bottom: 12px;">
                        综合评级</div>

                    <div style="font-weight: normal;font-size: 16px;width: 580px;color: #666666;padding-bottom: 15px;">{{
                        '一级（优秀>31分），二级（良好28-31分），三级（合格20-27分），四级（不合格<20分）身高体重（必测）（最高5分） 六个单项（最高5分）' }}</div>
                            <a-form-item label="自定义评价标题" v-bind="validateInfos.zdypjbt">
                                <div style="display: flex;align-items: center;position: relative;">
                                    <a-input v-model:value="modelRef.pjbt"
                                        @blur="validate('zdypjbt', { trigger: 'blur' }).catch(() => { })" />
                                </div>
                            </a-form-item>
                            <a-form-item label="自定义评价标题" v-bind="validateInfos.zdypjnr">
                                <div style="display: flex;align-items: center;position: relative;">
                                    <a-input v-model:value="modelRef.pjnr"
                                        @blur="validate('zdypjnr', { trigger: 'blur' }).catch(() => { })" />
                                </div>
                            </a-form-item>

                </a-card>
                <div style="width: (100% - 50px);;display: flex;align-items: center;justify-content: center;height: 80px;">
                    <a-form-item :wrapper-col="{ span: 14, offset: 4 }">
                        <a-button :loading="loading" type="primary" @click.prevent="onSubmit"
                            class="ivu-btn-blue">生成体测报告</a-button>
                    </a-form-item>
                </div>
            </a-form>
        </div>

    </div>
</template>

<script>
import { defineComponent, reactive, toRaw, getCurrentInstance } from 'vue';
import { Form } from 'ant-design-vue';
const useForm = Form.useForm;
export default {
    name: 'addTest',
    components: {
    },
    props: {
    },
    setup() {
        const instance = getCurrentInstance();
        const that = instance.appContext.config.globalProperties;
        let { phone, name, age, sex} = that.$route.query;
        const modelRef = reactive({
            testTime: null,  sex: sex, age: age, birth: null, height: null, weight: null, smzfp: null, ldty: null, wqzy: null, sjlxt: null, zwtqq: null, zphm: null, pjnr: null, pjbt: null, phone: phone, name: name
        });
        const rulesRef = reactive({
            testTime: [{
                required: true,
                message: '不能为空',
            }],
            name: [{
                required: true,
                message: '不能为空',
            }],
            height: [{
                required: true,
                message: '不能为空',
            }],
            weight: [{
                required: true,
                message: '不能为空',
            }],
            sex: [{
                required: true,
                message: '不能为空',
            }],
            age: [{
                required: true,
                message: '不能为空',
            }],
            phone: [{
                required: true,
                min: 11,
                max: 11,
                pattern: /^[1][3,4,5,7,8][0-9]{9}$/,
                message: '请输入正确的手机号'
            }],
            smzfp: [{
                required: true,
                message: '不能为空',
            }],
            ldty: [{
                required: true,
                message: '不能为空',
            }],
            zphm: [{
                required: true,
                message: '不能为空',
            }],
            zwtqq: [{
                required: true,
                message: '不能为空',
            }],
            sjlxt: [{
                required: true,
                message: '不能为空',
            }],
            wqzy: [{
                required: true,
                message: '不能为空',
            }],
        });
        const {
            resetFields,
            validate,
            validateInfos,
        } = useForm(modelRef, rulesRef);

        const methods = {
            onSubmit() {
                validate().then(res => {
                    console.log('发送的数据', { ...modelRef })
                    that.loading = true;
                    that.$http.post('admin.addTest', {
                        ...modelRef
                    }).then(res => {
                        that.loading = false;
                        that.$message.success('添加成功！')
                        that.$router.go(-1)

                    }).catch(err => {
                        that.loading = false;
                    }).finally(err => {
                        that.loading = false;
                    })
                }).catch(err => {
                    console.log(err)
                    that.$message.error('请完成填写后再提交！')
                })
            }
        }
        // const formState = reactive({});

        return {
            // formState,
            labelCol: {
                span: 3,
            },
            wrapperCol: {
                span: 6,
            },
            validate,
            validateInfos,
            resetFields,
            modelRef,
            ...methods,
        };
    },
    created() {
    },
}
</script>
