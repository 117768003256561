<style scoped>
.ivu-btnd {
    background-color: #19be6b;
    color: white;
    cursor: pointer;
    border: none;
    padding: 2px 13px;
    border-radius: 5px;
    font-size: 12px;
    /* margin-left: 14px; */
    box-sizing: border-box;
}

.ivu-btnd:hover {
    background-color: #47cb89;
}
</style>
<template>
    <div style="width: 100%;height: 100%;position: relative;">
        <img src="../assets/login-bg.png" style="width: 100%;height: auto;" alt="">
        <div style="background-color: #fff;position: absolute;right: 20px;top: 80px;border-radius: 4px;width: 380px;">
            <div style="font-size: 13px;font-weight: 600;color: #393d42;padding:15px 15px;flex: 1;text-align: left;">欢迎回来
            </div>
            <div
                style="width: 100%;border-top: 1px solid #eeeeee;box-sizing: border-box;padding:5px 12px 12px 12px;line-height: 38px;font-size: 18px;color: #393d42;">
                <div>账号</div>
                <a-input style="height: 40px;" v-model:value="account"></a-input>
                <div>密码</div>
                <a-input style="height: 40px;" v-model:value="password"></a-input>
                <a-button :loading="loading" type="primary"
                    style="font-size: 12px;flex: 1;width: 100%;height: 45px;margin-top: 12px;font-size: 15px;"
                    class="ivu-btnd" @click="login">登录</a-button>
            </div>

        </div>
    </div>
</template>

<script>
import { defineComponent, ref, toRaw, getCurrentInstance } from 'vue';

export default {
    name: 'indexA',
    props: {
    },
    setup(props, context) {
        const instance = getCurrentInstance();
        const that = instance.appContext.config.globalProperties;
        console.log(that)
        const data = {
            loading: false,
            account: ref(''),
            password: ref(''),
        }
        const methods = {
            async login() {
                that.loading = true;
                console.log('发送的数据', data.account)
                that.$http.post('admin.login', {
                    _account: data.account.value,
                    _password: data.password.value,
                }).then(res => {
                    console.log('登录返回的数据', res)
                    that.loading = false;
                    that.$store.set('_token', res._token)
                    console.log('惺惺惜惺惺', that.$store.get('_token'))
                    that.$router.push('/')
                }).catch(err => {
                }).finally(err => {
                    that.loading = false;
                })

            }
        }
        return {
            ...data,
            ...methods
        };
    }
}
</script>
