
<style>
.ivu-btn {
    background-color: #19be6b;
    color: white;
    cursor: pointer;
    border: none;
    padding: 5px 6px;
    border-radius: 3px;
    font-size: 12px;
    margin-left: 14px;
}

.ivu-btn-type2 {
    background-color: #1488f5;
    color: white;
    cursor: pointer;
    border: none;
    padding: 5px 6px;
    border-radius: 3px;
    font-size: 12px;
    margin-left: 14px;

}

.ivu-btn:hover {
    background-color: #47cb89;
}

.ivu-btn-type2:hover {
    background-color: #418bd1;
}
</style>
<template>
    <div style="width: 100%;height: 100%;">
        <div style="box-sizing:border-box;padding:0 30px;position: relative;">
            <div style="position: absolute;top: -35px;font-size: 14px;display: flex;align-items: center;">导入时间：<div
                    style="min-width: 100px;">{{ testTime }}</div>失败数量 <span style="color: red;margin-left: 5px;">{{
                        list.length }}</span></div>
            <a-table bordered="true" size="middle" :dataSource="list" :columns="columns">
            </a-table>
        </div>
    </div>
</template>
  
<script>
import { defineComponent, ref, toRaw, onMounted, getCurrentInstance } from 'vue';
import { useRoute } from "vue-router"

export default {
    name: 'fitnessA',
    props: {
    },
    methods: {
        createNewTest() {
            this.$router.push('./addTest')
        },
        gopldfExcl() {
            // window.open('/pldfExcl', '_blank')
        }
    },
    setup(props) {
        const instance = getCurrentInstance();
        const that = instance.appContext.config.globalProperties;
        const route = useRoute()
        console.log(route.query.id)
        let testTime = ref('');
        let list = ref([]);
        onMounted(() => {
            methods.getFailList()
        })
        const methods = {
            getFailList(id) {
                if (route.query.id) {
                    that.$http.post('admin.getFailList', {
                        id: route.query.id
                    }).then(res => {
                        list.value = res.list
                    }).catch(err => {
                    }).finally(err => {
                    })
                }
            }
        }
        return {
            testTime: testTime,
            list: list,
            ...methods,

            columns: [
                {
                    title: '编号',
                    dataIndex: 'num',
                    key: 'num',
                    width: 200,
                },
                {
                    title: '姓名',
                    dataIndex: 'name',
                    key: 'name',
                },
                {
                    title: '性别',
                    dataIndex: 'sex',
                    key: 'sex',
                },
                {
                    title: '手机',
                    dataIndex: 'phone',
                    key: 'phone',
                },
            ],
        };
    },
}
</script>
  