<style >
.btn {
    --btn-backgroundColor: #ed4014;
    --btn-hover-backgroundColor: #bd4014;
}

.btn {
    background-color: var(--btn-backgroundColor);
    color: white;
    cursor: pointer;
    border: none;
    padding: 4px 8px;
    border-radius: 3px;
    font-size: 12px;
    /* margin-left: 14px; */
    margin: 2px 0 2px 14px;
    box-sizing: border-box;

}

.btn:hover {
    background-color: var(--btn-hover-backgroundColor);
}

:where(.css-dev-only-do-not-override-j6gjt1).ant-card .ant-card-head {
    font-size: 14px;
}

:where(.css-dev-only-do-not-override-j6gjt1).ant-form-item .ant-form-item-label>label {
    font-size: 16px;
    font-weight: normal;
}
</style>
<template>
    <div style="width: 100%;height: 100%;position: relative;">
        <div style="padding:25px 25px 0 25px;font-weight: 600;font-size: 18px;">
            <div style="color: #555555;">体测报告</div>
        </div>
        <div style="box-sizing:border-box;padding:30px;">
            <a-table :loading="loading" bordered="true" size="middle" :dataSource="dataSource" :columns="columns">
                <template #sex="{ record }">
                    <div>
                        {{ record.sex == 0 ? '男' : '女' }}
                    </div>
                </template>
                <template #operation="{ record }">
                    <div>
                        <button type="button" @click="deleteTest(record)" class="btn">
                            <span>删除</span>
                        </button>


                        <button type="button" @click="check(record)" class="btn"
                            style="--btn-backgroundColor: #1faef0;--btn-hover-backgroundColor: #47c5f7fa;">
                            <span>查看数据</span>
                        </button>
                    </div>
                </template>
            </a-table>
        </div>
        <Modal ref="modal"></Modal>
    </div>
</template>

<script>
import Modal from '../components/Modal/Modal';
import { defineComponent, reactive, toRaw, getCurrentInstance, ref, onMounted } from 'vue';
export default {
    name: 'testRecord',
    components: {
        Modal
    },
    props: {
    },
    setup() {
        const instance = getCurrentInstance();
        const that = instance.appContext.config.globalProperties;
        const dataSource = ref([])
        let { phone, name } = that.$route.query;
        onMounted(() => {
            methods.getTestRecord()
        })
        const methods = {
            getTestRecord() {
                that.$http.post('admin.getTestRecord', {
                    phone: phone,
                    name: name
                }).then(res => {
                    dataSource.value = res.list
                }).catch(err => {
                }).finally(err => {
                })
            },
            deleteTest(record) {
                instance.refs.modal.show({
                    title: '删除报告',
                    content: '是否删除?',
                    confirmText: '确定'
                }, () => {
                    return that.$http.post('admin.deleteTest', {
                        ...record
                    }).then(res => {
                        that.$message.success('报告已删除！')
                        methods.getTestRecord()
                    }).catch(err => {
                    }).finally(err => {
                    })
                })
            },
            check(record) {
                console.log(record)
                instance.refs.modal.show({
                    title: '体测数据',
                    footer:false,
                    showConfirmButton:false,
                    form: [
                        {
                            name: 'id',
                            label: 'id',
                            component: 'input',
                            hide: true,
                            value: record.id
                        },
                        {
                            name: 'smzfp',
                            label: '十米折返跑',
                            component: 'label',
                            disabled:true,
                            value: record.smzfp + ' 秒'
                        },
                        {
                            name: 'ldty',
                            label: '立定跳远',
                            component: 'label',
                            disabled:true,
                            value: record.ldty + ' 米'
                        },
                        {
                            name: 'wqzy',
                            label: '网球掷远',
                            component: 'label',
                            disabled:true,
                            value: record.wqzy + ' 米'
                        },
                        {
                            name: 'sjlxt',
                            label: '双脚连续跳',
                            component: 'label',
                            disabled:true,
                            value: record.sjlxt + ' 秒'
                        },
                        {
                            name: 'zwtqq',
                            label: '坐位体前屈',
                            component: 'label',
                            disabled:true,
                            value: record.sjlxt + ' 厘米'
                        },
                        {
                            name: 'zphm',
                            label: '走平衡木',
                            component: 'label',
                            disabled:true,
                            value: record.sjlxt + ' 秒'
                        },
                    ]
                }, () => {
                   
                })
            }
        }

        return {
            dataSource,
            ...methods,
            columns: [
                {
                    title: '时间',
                    dataIndex: 'createTime',
                    key: 'createTime',
                    width: 150,
                },
                {
                    title: '测试日期',
                    dataIndex: 'testTime',
                    key: 'testTime',
                    width: 100,
                },
                {
                    title: '姓名',
                    dataIndex: 'name',
                    key: 'name',
                    width: 100,
                },
                {
                    title: '性别',
                    dataIndex: 'sex',
                    key: 'sex',
                    width: 100,
                    slots: { customRender: 'sex' },
                },
                {
                    title: '年龄',
                    dataIndex: 'age',
                    key: 'age',
                    width: 100,
                },
                {
                    title: '操作',
                    dataIndex: 'operation',
                    key: 'operation',
                    width: 200,
                    slots: { customRender: 'operation' },
                },
            ],
        }
    },
    created() {
    },
}
</script>
