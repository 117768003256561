
import account from './content/account';
import Fitness from './content/Fitness';
import index from './content/index';
import login from './content/login';
import addTest from './content/addTest';
import student from './content/student';
import testRecord from './content/testRecord';
import pldfExcl from './content/pldfExcl';

const routes = [
    {
        path: '/',
        name: 'index',
        component: index
    },
    {
        path: '/account',
        name: 'account',
        component: account
    },
    {
        path: '/fitness',
        name: 'Fitness',
        component: Fitness
    },
    {
        path: '/login',
        name: 'login',
        component: login
    },
    {
        path: '/addTest',
        name: 'addTest',
        component: addTest
    },
    {
        path: '/student',
        name: 'student',
        component: student
    },
    {
        path: '/testRecord',
        name: 'testRecord',
        component: testRecord
    },
    {
        path: '/pldfExcl',
        name: 'pldfExcl',
        component: pldfExcl
    }
]
export default routes;